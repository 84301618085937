+
<template>
  <div class="vuse-content-wrapper">
    <smart-breadcrumbs :items="navigationElements" />
    <v-card>
      <div class="filters">
        <h3>Pregunta {{ question.text ? ': ' + question.text : '' }}</h3>
        <h4 class="text-center">Listado de respuestas</h4>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              class="mb-2"
              style="float: right"
              @click.prevent="handleCreate()"
              >Crear
            </v-btn>
          </template>
          <span>Agregar respuesta</span>
        </v-tooltip>

        <label>Filtrar por:</label>
        <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row mx-0">
          <v-row class="mx-0" style="max-width: calc(100% - 90px)">
            <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
              <v-text-field
                dense
                solo
                hide-details="auto"
                label="Búsqueda"
                v-model="filters.search"
              />
            </v-col>
          </v-row>
          <v-btn
            color="primary"
            style="min-width: auto; position: absolute; right: 50px; height: 25px"
            dark
            class="px-1 py-1"
            v-if="filterActivated"
            @click.prevent="
              filterActivated = false
              cleanFilters()
              getAnswers()
            "
          >
            <v-icon dark> mdi-delete </v-icon>
          </v-btn>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
                color="primary"
                style="min-width: auto; position: absolute; right: 10px; height: 25px"
                dark
                class="px-1 py-1"
                @click.prevent="
                  filterActivated = true
                  getAnswers()
                "
                ><v-icon dark> mdi-filter </v-icon>
              </v-btn>
            </template>
            <span>Filtrar</span>
          </v-tooltip>
        </v-form>
      </div>

      <simple-list
        :title="''"
        :dense="true"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :itemKey="'id'"
        :hideFooter="true"
        :hideHeader="false"
        :multiSort="false"
        :loading="loading"
        :class="'my-2 pb-2'"
        :sortable="false"
        :page="page"
        :pages="pages"
        :extraButtoms="false"
        :showCreate="false"
        :showDelete="true"
        :buttonLink="'empresas/ficha'"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
      />
    </v-card>
    <modal name="crudModal" :height="'80%'" :width="'80%'" :adaptive="true">
      <v-card class="pa-4" elevation="0">
        <h2 class="text-center mb-4" v-if="flow === 'create'">Crear Respuesta</h2>
        <h2 class="text-center mb-4" v-if="flow === 'edit'">Editar Respuesta</h2>

        <span v-if="flow === 'edit' && item.created_at"
          >Fecha de creación:
          {{
            createdAt.toLocaleDateString('es-VE', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })
          }}. {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
        >
        <div class="row mx-0 mt-4">
          <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row">
            <v-col cols="12" md="8">
              <v-text-field
                counter
                :rules="[rules.required]"
                label="Escribe la respuesta"
                v-model="text"
              />
            </v-col>
            <v-col cols="12" md="4" sm="12">
              <v-checkbox :label="'Respuesta válida'" v-model="active" />
            </v-col>
          </v-form>
        </div>
      </v-card>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'create'"
            :disabled="!valid"
            color="primary"
            @click="createItem()"
          >
            Crear
          </v-btn>
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'edit'"
            :disabled="!valid"
            color="primary"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="reset"
          >
            Limpiar
          </v-btn>
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="$modal.hide('crudModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import SimpleList from '@/components/Listing/SimpleList.vue'
  import { decryptData } from '@/utils/encryption'

  export default {
    name: 'QuestionAswers',
    components: {
      SimpleList,
    },
    data() {
      return {
        config: {},
        metrics: [],
        loading: true,
        question: {},
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        headers: [
          {
            text: '#',
            value: 'id',
            align: ' d-none',
          },
          { text: 'Respuesta', value: 'text' },
          { text: 'Válida', value: 'validText', align: 'center' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '150px' },
        ],
        items: [],
        navigationElements: [
          {
            text: 'Elearning',
            disabled: false,
            query: null,
            params: null,
            name: 'business/elearning/AdminPanel',
            href: '/business/elearning/admin-panel',
          },
          {
            text: 'Cursos',
            disabled: false,
            query: null,
            params: null,
            name: 'business/elearning/Courses',
            href: '/business/elearning/courses',
          },
          {
            text: 'Gestor del Examen',
            disabled: false,
            query: null,
            params: null,
            name: 'business/elearning/Courses',
            href: '/business/elearning/courses',
          },
          {
            text: 'Respuestas',
            disabled: true,
          },
        ],
        metricsSearched: [{ id: 0, name: 'No posee empresa principal' }],
        page: 1,
        pages: 1,
        item: {},
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        filters: {
          search: '',
        },
        text: '',
        active: false,
        loadingText: 'Cargando registros',
        valid: true,
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 200 : true) || 'Debe poseer menos de 200 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          url: (v) => (v ? this.isURL(v) : true) || 'La URL es inválida',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
      }
    },
    watch: {},
    created() {
      this.getQuestion()
    },
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
    },
    methods: {
      cleanFilters() {
        this.filters = {
          search: '',
        }
      },

      async getAnswers() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          let searchString = ''
          let periodicityString = ''
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
          if (this.filters.periodicity && this.filters.periodicity.id) {
            periodicityString = '&periodicity=' + this.filters.periodicity.id
          }
          queryParams = searchString + periodicityString
        }
        this.$axios
          .get(
            'answers?question_id=' +
              this.question.id +
              '&page=' +
              this.page +
              orderString +
              queryParams
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.forEach((answer) => {
              answer.validText = answer.valid ? 'Si' : 'No'
            })
            this.loading = false
          })
      },

      async getQuestion() {
        await this.$axios
          .get('questions/' + this.$route.params.questionId)
          .then((response) => {
            this.question = response.data
            this.getAnswers()
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.text = ''
        this.active = false

        this.$modal.show('crudModal')
      },

      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getAnswers()
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.text = this.item.text
        this.active = this.item.valid
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )

        this.$modal.show('crudModal')
      },

      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },

      async createItem() {
        this.active = this.active ? 1 : 0
        this.$axios
          .post('answers', {
            question_id: this.question.id,
            text: this.text,
            valid: this.active,
          })
          .then(() => {
            this.page = 1
            this.getAnswers()
            this.$modal.hide('crudModal')
          })
      },

      async saveItem() {
        this.active = this.active ? 1 : 0
        this.$axios
          .patch('answers/' + this.item.id, {
            question_id: this.question.id,
            text: this.text,
            valid: this.active,
          })
          .then(() => {
            this.page = 1
            this.getAnswers()
            this.$modal.hide('crudModal')
          })
      },

      async deleteItem(item) {
        this.$axios.delete('answers/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.getAnswers()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getAnswers()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
</style>
